import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectDataCacheService } from 'src/app/project-data-cache.service';
import { DialogService } from '../dialogservice.service';
import { Router } from '@angular/router';
import { ProjectsService } from '../utils/services/projects.service';
import { KeycloakService } from 'keycloak-angular';
import { SharedService } from '../utils/services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgentsService } from '../utils/services/agents.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomersService } from '../utils/services/customers.service';
import { PartnersService } from '../utils/services/partners.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { MaterialModule } from '../material.module';
import { DateFormatDirective } from '../utils/date-format.directive';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

const DateFormats = {
  parse: {
    dateInput: ['DD-MM-YYYY', 'DD.MM.YYYY', 'DD/MM/YYYY'],
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'newProject',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    TimePickerModule,
    DateFormatDirective
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DateFormats },
  ],
  templateUrl: './newProject.component.html',
  styleUrls: ['./newProject.component.scss'],
})
export class NewProjectComponent implements OnInit {
  @Input()
  public prefix: string = '';

  @Input()
  selectedProject: any = null;

  @Input()
  isDescriptionModal: boolean = false;

  // @Output() newCreatedProject = new EventEmitter<any>();
  @Output() thumnailUpdated = new EventEmitter<any>();

  public projectTitle = '';
  public numberOfAgentsRequested = '';
  public startDate:any = '';
  public mondayStart = '';
  public mondayEnd = '';
  public tuesdayStart = '';
  public tuesdayEnd = '';
  public wednesdayStart = '';
  public wednesdayEnd = '';
  public thursdayStart = '';
  public thursdayEnd = '';
  public fridayStart = '';
  public fridayEnd = '';
  public saturdayStart = '';
  public saturdayEnd = '';
  public sundayStart = '';
  public sundayEnd = '';
  public taskDescription = '';
  public requirements = '';
  public conditions = '';
  isEditable: boolean = false;
  partnerUsersList: any[] = [];
  clientUsersList: any[] = [];
  selectedPartnerUsersList: any[] = [];
  selectedPartnerUsersListIds: string[] = [];
  partnersList: string[] = [];
  selectedPartnersList: string[] = [];
  public currentRole: string = '';
  public currentUser: string = '';
  public currentUserId: any = '';
  public boundValue: string = '';
  emailUpdates: boolean = true;

  public interval: number = 15;
  public formatString: string = 'HH:mm';

  defaultthumbnailImageSrc =
    'https://github.com/Photonic-Codes-GmbH/resource-collection/blob/main/Test%20Logo.png?raw=true';
  thumbnailImageSrc = this.defaultthumbnailImageSrc;
  projectThumbnailPhoto: any;

  public selectedCustomerName: string = '';

  public constructor(
    public readonly projectDataCacheService: ProjectDataCacheService,
    public dialogService: DialogService,
    private router: Router,
    public projectsService: ProjectsService,
    protected readonly keycloak: KeycloakService,
    private sharedService: SharedService,
    private snackBar: MatSnackBar,
    private agentsService: AgentsService,
    private dateAdapter: DateAdapter<any>,
    public readonly customersService: CustomersService,
    public readonly partnersService: PartnersService
  ) {
    this.dateAdapter.setLocale('de-DE');
  }

  public ngOnInit(): void {
    this.customersService.fetchAll(() => {});
    this.partnersService.fetchAll(() => {
      this.selectedPartnersList = this.selectedProject?.partners ?? [];
      this.partnersList = this.partnersService.partnerNames.filter((partner) => !this.selectedPartnersList.includes(partner));
    });

    this.fetchUsersByRole('Partners');
    this.fetchUsersByRole('Clients');

    // if the selectedProject is not null, we set the values of the selectedProject to the variables
    this.projectTitle = this.selectedProject?.name || '';
    this.numberOfAgentsRequested =
      this.selectedProject?.numberOfAgentsRequested || '';

    this.startDate = this.selectedProject?.startDate
      ? new Date(this.selectedProject.startDate).toISOString().split('T')[0]
      : '';
    // this.selectedClient = this.clientsList.find((client: any) => client.id == this.selectedProject.client);
    if (this.selectedProject?.workingHours) {
      this.formateTime(this.selectedProject.workingHours);
    }
    this.mondayStart = this.selectedProject?.workingHours?.mondayStart || '';
    this.mondayEnd = this.selectedProject?.workingHours?.mondayEnd || '';
    this.tuesdayStart = this.selectedProject?.workingHours?.tuesdayStart || '';
    this.tuesdayEnd = this.selectedProject?.workingHours?.tuesdayEnd || '';
    this.wednesdayStart =
      this.selectedProject?.workingHours?.wednesdayStart || '';
    this.wednesdayEnd = this.selectedProject?.workingHours?.wednesdayEnd || '';
    this.thursdayStart =
      this.selectedProject?.workingHours?.thursdayStart || '';
    this.thursdayEnd = this.selectedProject?.workingHours?.thursdayEnd || '';
    this.fridayStart = this.selectedProject?.workingHours?.fridayStart || '';
    this.fridayEnd = this.selectedProject?.workingHours?.fridayEnd || '';
    this.saturdayStart =
      this.selectedProject?.workingHours?.saturdayStart !== '00:00:00'
        ? this.selectedProject?.workingHours?.saturdayStart || ''
        : '';
    this.saturdayEnd =
      this.selectedProject?.workingHours?.saturdayEnd !== '00:00:00'
        ? this.selectedProject?.workingHours?.saturdayEnd || ''
        : '';
    this.sundayStart =
      this.selectedProject?.workingHours?.sundayStart !== '00:00:00'
        ? this.selectedProject?.workingHours?.sundayStart || ''
        : '';
    this.sundayEnd =
      this.selectedProject?.workingHours?.sundayEnd !== '00:00:00'
        ? this.selectedProject?.workingHours?.sundayEnd || ''
        : '';

    this.taskDescription = this.selectedProject?.taskDescription || '';
    this.requirements = this.selectedProject?.requirements || '';
    this.conditions = this.selectedProject?.conditions || '';
    this.boundValue = this.selectedProject?.boundValue || '';
    this.emailUpdates = this.selectedProject?.id
      ? this.selectedProject.emailUpdates
      : true;

    if (this.selectedProject && this.selectedProject.projectThumbnailPhoto && !this.isDescriptionModal) {
      this.thumbnailImageSrc = this.selectedProject.projectThumbnailPhoto;
    } else if (this.isDescriptionModal && this.selectedProject && this.selectedProject.projectThumbnailPhoto && this.selectedProject.projectThumbnailPhoto.photoBlob) {
      this.thumbnailImageSrc = this.agentsService.convertImagetoUrl(
        this.selectedProject.projectThumbnailPhoto?.photoBlob
      );
    }
    const tokenData = this.keycloak.getKeycloakInstance().tokenParsed;

    const userRoles = tokenData?.realm_access?.roles;
    this.currentUser = tokenData?.['email'];
    this.currentUserId = tokenData?.['sub'];
    if (userRoles?.includes('Client')) {
      this.currentRole = 'Client';
      this.selectedCustomerName = this.selectedProject.customer;
    } else if (userRoles?.includes('Partner')) {
      this.currentRole = 'Partner';
    } else if (userRoles?.includes('Admin')) {
      this.currentRole = 'Admin';
    }
  }

  validateForm(): boolean {
    if (this.selectedProject) {
      return (
        !!this.selectedProject.numberOfAgentsRequested &&
        this.validateWorkingHours() &&
        !!this.selectedProject.taskDescription &&
        !!this.selectedProject.requirements &&
        !!this.selectedProject.conditions &&
        !!this.selectedProject.boundValue &&
        !!this.selectedProject.name &&
        !!this.selectedProject.customer
      );
    }
    return false;
  }

  validateWorkingHours(): boolean {
    return (
      !!this.selectedProject.workingHours &&
      !!this.selectedProject.workingHours.mondayStart &&
      !!this.selectedProject.workingHours.mondayEnd &&
      !!this.selectedProject.workingHours.tuesdayStart &&
      !!this.selectedProject.workingHours.tuesdayEnd &&
      !!this.selectedProject.workingHours.wednesdayStart &&
      !!this.selectedProject.workingHours.wednesdayEnd &&
      !!this.selectedProject.workingHours.thursdayStart &&
      !!this.selectedProject.workingHours.thursdayEnd &&
      !!this.selectedProject.workingHours.fridayStart &&
      !!this.selectedProject.workingHours.fridayEnd
    );
  }

  saveProject() {
    if (this.startDate instanceof Date) {
      this.startDate = this.startDate.toISOString().split('T')[0];
    }

    if (this.selectedProject != null && this.selectedProject.id != null) {
      this.selectedProject.name = this.projectTitle;
      this.selectedProject.numberOfAgentsRequested = this.numberOfAgentsRequested;
      this.selectedProject.taskDescription = this.taskDescription;
      this.selectedProject.requirements = this.requirements;
      this.selectedProject.startDate = this.startDate;
      this.selectedProject.boundValue = this.boundValue;
      this.selectedProject.conditions = this.conditions;
      this.selectedProject.assignedPartners = this.selectedPartnerUsersListIds;
      this.selectedProject.emailUpdates = this.emailUpdates;
      this.selectedProject.customer = this.selectedCustomerName;
      this.selectedProject.partners = this.selectedPartnersList;

      this.selectedProject.workingHours = {
        mondayStart: this.mondayStart,
        mondayEnd: this.mondayEnd,
        tuesdayStart: this.tuesdayStart,
        tuesdayEnd: this.tuesdayEnd,
        wednesdayStart: this.wednesdayStart,
        wednesdayEnd: this.wednesdayEnd,
        thursdayStart: this.thursdayStart,
        thursdayEnd: this.thursdayEnd,
        fridayStart: this.fridayStart,
        fridayEnd: this.fridayEnd,
        saturdayStart: this.saturdayStart == '' ? null : this.saturdayStart,
        saturdayEnd: this.saturdayEnd == '' ? null : this.saturdayEnd,
        sundayStart: this.sundayStart == '' ? null : this.sundayStart,
        sundayEnd: this.sundayEnd == '' ? null : this.sundayEnd,
      };

      const { projectThumbnailPhoto:projecPhoto, ...rest } = this.selectedProject;
      this.formateTime(this.selectedProject.workingHours);

      if (this.validateForm()) {
        this.dialogService.showNewProjectDialog = false;
        this.projectsService.updateProject(this.selectedProject.id, rest).subscribe();
      } else {
        this.snackBar.open('Erforderliche Felder fehlen', '', {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'top',
        });
      }
    } else {
      this.selectedProject = {
        name: this.projectTitle,
        numberOfAgentsRequested: this.numberOfAgentsRequested,
        startDate: this.startDate,
        workingHours: {
          mondayStart: this.mondayStart,
          mondayEnd: this.mondayEnd,
          tuesdayStart: this.tuesdayStart,
          tuesdayEnd: this.tuesdayEnd,
          wednesdayStart: this.wednesdayStart,
          wednesdayEnd: this.wednesdayEnd,
          thursdayStart: this.thursdayStart,
          thursdayEnd: this.thursdayEnd,
          fridayStart: this.fridayStart,
          fridayEnd: this.fridayEnd,
          saturdayStart: this.saturdayStart == '' ? null : this.saturdayStart,
          saturdayEnd: this.saturdayEnd == '' ? null : this.saturdayEnd,
          sundayStart: this.sundayStart == '' ? null : this.sundayStart,
          sundayEnd: this.sundayEnd == '' ? null : this.sundayEnd,
        },
        taskDescription: this.taskDescription,
        requirements: this.requirements,
        assignedPartners:
          this.selectedPartnerUsersList && this.selectedPartnerUsersListIds
            ? this.selectedPartnerUsersListIds
            : null,
        customer: this.selectedCustomerName,
        partners: this.selectedPartnersList,
        boundValue: this.boundValue,
        conditions: this.conditions,
        emailUpdates: this.emailUpdates,
      };

      this.formateTime(this.selectedProject.workingHours);

      if (this.validateForm()) {
        this.projectsService
          .createProject(this.selectedProject)
          .subscribe((response: any) => {
            if (this.projectThumbnailPhoto && this.projectThumbnailPhoto.type) {
              this.uploadProfilePhoto(
                this.projectThumbnailPhoto,
                this.projectThumbnailPhoto.type,
                response.id,
                false
              );
            }else{
              this.dialogService.showNewProjectDialog = false;
              this.router.navigate(['/singleProjectView/' + response.id]);
            }
          });
      } else {
        this.snackBar.open('Erforderliche Felder fehlen', '', {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'top',
        });
      }
    }
  }

  toggleEditable() {
    this.isEditable = !this.isEditable;
    if (this.isEditable) {
      const heading = document.querySelector('.heading h1');
      if (heading) {
        (heading as HTMLElement).focus();
      }
    }
  }

  onBlur(event: any) {
    this.isEditable = false;
  }

  fetchUsersByRole(role: string): void {
    this.sharedService.getUsersOfPartnersGroup(role).subscribe((users: any) => {
      if (role === 'Partners') {
        this.partnerUsersList = users;
        if (
          this.selectedProject != null &&
          this.selectedProject.id != null &&
          !!this.selectedProject.assignedPartners
        ) {
          this.selectedPartnerUsersList = this.partnerUsersList.filter((partner: any) =>
            this.selectedProject.assignedPartners.includes(partner.id)
          );
          this.partnerUsersList = this.partnerUsersList.filter(
            (partner) =>
              !this.selectedProject.assignedPartners.includes(partner.id)
          );
          this.selectedPartnerUsersList.forEach((partner: any) => {
            this.selectedPartnerUsersListIds.push(partner.id);
          });
        }
      } else if (role === 'Clients') {
        this.clientUsersList = users;
        if (
          this.selectedProject != null &&
          this.selectedProject.id != null &&
          !!this.selectedProject.customer
        ) {
          this.selectedCustomerName = this.selectedProject.customer;
        }
      }
    });
  }

  addPartner(i: number) {
    const selectedPartner = this.partnersList[i];
    this.selectedPartnersList.push(selectedPartner);
    this.partnersList.splice(i, 1);
  }

  removePartner(i: number) {
    const removedPartner = this.selectedPartnersList[i];
    this.partnersList.push(removedPartner);
    this.selectedPartnersList.splice(i, 1);
  }

  adoptTime() {
    this.tuesdayStart = this.mondayStart;
    this.tuesdayEnd = this.mondayEnd;
    this.wednesdayStart = this.mondayStart;
    this.wednesdayEnd = this.mondayEnd;
    this.thursdayStart = this.mondayStart;
    this.thursdayEnd = this.mondayEnd;
    this.fridayStart = this.mondayStart;
    this.fridayEnd = this.mondayEnd;
  }

  updateProjectTitle(event: any) {
    this.projectTitle = event.target.innerText;
  }

  formateTime(workingHours: any) {
    const excludedFields = ['updatedAt', 'createdAt', 'id'];
    Object.keys(workingHours).forEach((key) => {
      if (!!workingHours[key] && !excludedFields.includes(key)) {
        if (workingHours[key].toString().length > 11) {
          const time = new Date(workingHours[key]);
          const hours = time.getHours();
          const minutes = time.getMinutes();
          workingHours[key] = `${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}`;
        } else {
          workingHours[key] = `${workingHours[key]?.split(':')[0]}:${
            workingHours[key].split(':')[1]
          }`;
        }
      }
    });
  }

  chooseProfilePhoto(event: any): void {
    this.projectThumbnailPhoto = event.target.files[0];
    if (this.selectedProject != null && this.selectedProject.id != null) {
      this.uploadProfilePhoto(
        this.projectThumbnailPhoto,
        this.projectThumbnailPhoto.type,
        this.selectedProject.id,
        true
      );
    } else {
      const reader: any = new FileReader();
      reader.onload = (event: any) => {
        this.thumbnailImageSrc = event.target.result;
      };
      reader.readAsDataURL(this.projectThumbnailPhoto);
    }
  }

  uploadProfilePhoto(
    profilePhoto: any,
    type: string,
    projectId: number,
    isEdit: boolean
  ) {
    let blob: any;
    const reader: any = new FileReader();
    reader.readAsArrayBuffer(profilePhoto);
    reader.onload = () => {
      blob = new Blob([reader.result], { type: profilePhoto.type });
      const formData: any = new FormData();
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      const fileName = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;

      if (blob instanceof Blob) {
        formData.append('file', blob, fileName);
        formData.append('type', type);
      }
      this.projectsService
        .setProjectThumbnailPhoto(projectId, formData, false)
        .subscribe({
          next: (res: any) => {
            this.thumbnailImageSrc = this.agentsService.convertImagetoUrl(
              res.projectThumbnailPhoto?.photoBlob
            );
            const updatedThumbnail = {
              thumbnailImageSrc: this.thumbnailImageSrc,
              projectId
            }
            this.thumnailUpdated.emit(updatedThumbnail);
            this.projectThumbnailPhoto = null;
            if(!isEdit){
              this.dialogService.showNewProjectDialog = false;
              this.router.navigate(['/singleProjectView/' + projectId]);
            }else{
              this.selectedProject.projectThumbnailPhoto.photoBlob = res.projectThumbnailPhoto?.photoBlob;
            }
          },
          error: (error: any) => {
            this.projectThumbnailPhoto = null;
            console.log('Error:', error);
          },
        });
    };
  }
}
