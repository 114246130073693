import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CustomersService } from '../utils/services/customers.service';
import { Customer } from '../utils/models/customer.model';
import { DialogService } from '../dialogservice.service';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../spinner/spinner.component';
import { NewCustomerAssignmentComponent } from './new-customer-assignment/new-customer-assignment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'customer-management',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    SwitchModule,
    NewCustomerAssignmentComponent,
    SpinnerComponent,
    MatTooltipModule
  ],
  templateUrl: './customer-management.component.html',
  styleUrl: './customer-management.component.scss'
})
export class CustomerManagementComponent implements OnInit {
  public isSpinnerActive: boolean = false;

  public deleteConfirmationPopup: boolean = false;
  public customerForDelete?: { customer: Customer, user: any } = undefined;
  public selectedCustomer?: Customer = undefined;

  public deactivateConfirmationPopup: boolean = false;
  public customerForDeactivate?: Customer = undefined;
  public currentSwitch: any = {};

  @Output() public updateUsersPlease: EventEmitter<void> = new EventEmitter<void>();

  public constructor(
    public readonly customersService: CustomersService,
    public readonly dialogService: DialogService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  public ngOnInit(): void {
    this.isSpinnerActive = true;

    this.customersService.fetchAll(() => {
      this.isSpinnerActive = false;
    });
  }

  public addCustomerAssignment(): void {
    this.dialogService.showNewCustomerDialog = true;
    this.customersService.isForUpdate = false;
    this.selectedCustomer = undefined;
  }

  public onSwitch(event: any, customer: Customer, sw: any): void {
    this.customerForDeactivate = customer;
    this.currentSwitch = sw;

    if (event.checked) {
      this.customersService.reactivate(customer).subscribe(() => {
        this.deactivateConfirmationPopup = false;
        this.customerForDeactivate = undefined;
        this.currentSwitch = {};
        this.customersService.fetchAll(() => {});
        this.updateUsersPlease.emit();
      });
    } else {
      this.deactivateConfirmationPopup = true;
    }
  }

  public onSwitchDialogYes(): void {
    this.customersService.deactivate(this.customerForDeactivate!).subscribe(() => {
      this.deactivateConfirmationPopup = false;
      this.customerForDeactivate = undefined;
      this.currentSwitch = {};
      this.customersService.fetchAll(() => {});
      this.updateUsersPlease.emit();
    });
  }

  public onSwitchDialogNo(): void {
    setTimeout(() => {
      if (this.customerForDeactivate?.isActivated) {
        this.customerForDeactivate!.isActivated = true;
      }
      this.currentSwitch.checked = true;
      this.changeDetectorRef.detectChanges();

      this.deactivateConfirmationPopup = false;
      this.customerForDeactivate = undefined;
      this.currentSwitch = {};
    }, 500);
  }

  public onClickDelete(customerAssignment: { customer: Customer, user: any }): void {
    this.customerForDelete = customerAssignment;
    this.deleteConfirmationPopup = true;
  }

  public onClickUser(customerAssignment: { customer: Customer, user: any }): void {
    this.selectedCustomer = customerAssignment.customer;
    this.customersService.isForUpdate = true;
    this.dialogService.showNewCustomerDialog = true;
  }

  public onClickJa(): void {
    this.customersService.delete(this.customerForDelete!.customer.id).subscribe(() => {
      this.customersService.customerAssignments = this.customersService.customerAssignments.filter(
        (assignment) => assignment.customer.id !== this.customerForDelete!.customer.id
      );
      this.deleteConfirmationPopup = false;
      this.customerForDelete = undefined;
    });
  }
}
