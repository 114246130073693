<div class="app">
  <div class="heading">
    <div>
      <h2>Userverwaltung</h2>
      <!-- add search here -->
    </div>
    <button type="button" class="add-user-btn" (click)="onClickAddUser()"> Benutzer hinzufügen </button>
  </div>

  <div class="user-details">
    <table class="table mb-0">
      <thead>
        <tr>
          <th>Benutzernamen</th>
          <th>Mailadresse</th>
          <th>Name</th>
          <th>Rolle</th>
          <th style="display: flex; flex-direction: row; justify-content: flex-end; align-items: center;">Aktion</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users">
          <td>{{user.username}}</td>
          <td>{{user.email}}</td>
          <td>{{user.firstName}} {{user.lastName}}</td>
          <td>{{user.rolle}}</td>
          <td style="display: flex; flex-direction: row; justify-content: flex-end; align-items: center;">
            <ejs-switch [matTooltip]="user.enabled ? 'Deaktivieren' : 'Aktivieren'" #sw (change)="onUserEnabledSwitch($event, user, sw)" [checked]="user.enabled" cssClass="e-small"></ejs-switch>
            <button class="action-btn" matTooltip="Löschen" (click)="onClickDelete(user)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="rgba(238,14,14,1)"><path d="M4 8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8ZM6 10V20H18V10H6ZM9 12H11V18H9V12ZM13 12H15V18H13V12ZM7 5V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V5H22V7H2V5H7ZM9 4V5H15V4H9Z"></path></svg>
            </button>
            <button class="action-btn" (click)="onClickUser(user)" matTooltip="Aktualisieren">
              <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="20" xmlns="http://www.w3.org/2000/svg"
                id="screenshot-bb74426a-280e-8067-8003-ed21d11004ec" version="1.1" viewBox="22899 1213 20 20"
                height="20">
                <g id="shape-bb74426a-280e-8067-8003-ed21d11004ec" style="fill:#000000" width="1em" height="1em" rx="0"
                  ry="0">
                  <g id="shape-bb74426a-280e-8067-8003-ed21d1111ecb">
                    <g class="fills" id="fills-bb74426a-280e-8067-8003-ed21d1111ecb">
                      <path rx="0" ry="0" style="fill:#000000;fill-opacity:0.8"
                        d="M22909.000,1218.000L22908.106,1218.871L22911.594,1222.375L22904.000,1222.375L22904.000,1223.625L22911.594,1223.625L22908.106,1227.108L22909.000,1228.000L22914.000,1223.000Z">
                      </path>
                    </g>
                  </g>
                  <g id="shape-bb74426a-280e-8067-8003-ed21d11151d4">
                    <g class="fills" id="fills-bb74426a-280e-8067-8003-ed21d11151d4">
                      <path rx="0" ry="0" style="fill:#000000;fill-opacity:0.8"
                        d="M22909.000,1231.750C22904.168,1231.750,22900.250,1227.832,22900.250,1223.000C22900.250,1218.168,22904.168,1214.250,22909.000,1214.250C22913.832,1214.250,22917.750,1218.168,22917.750,1223.000C22917.744,1227.830,22913.830,1231.744,22909.000,1231.750ZM22909.000,1215.500C22904.858,1215.500,22901.500,1218.858,22901.500,1223.000C22901.500,1227.142,22904.858,1230.500,22909.000,1230.500C22913.142,1230.500,22916.500,1227.142,22916.500,1223.000C22916.495,1218.860,22913.140,1215.505,22909.000,1215.500Z">
                      </path>
                    </g>
                  </g>
                </g>
              </svg>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="dialogService.showNewUserDialog" class="backdrop"></div>
<app-new-user *ngIf="dialogService.showNewUserDialog" [selectedUser]="selectedUser" [isForUpdate]="isForUpdate" (changeinUser)="userChange($event)"></app-new-user>

<div *ngIf="deleteConfirmationPopup" class="backdrop"></div>
<div class="deleteConfirmation-popup-container"  *ngIf="deleteConfirmationPopup">
  <h2>
    Sind Sie sicher, dass Sie den Benutzer “{{this.userForDelete.firstName}} {{this.userForDelete.lastName}}” deaktivieren möchten?
  </h2>
  <div class="d-flex gap-4">
    <button (click)="onClickNein()" mat-stroked-button class="mt-3">Abbrechen</button>
    <button (click)="onClickJa()" mat-stroked-button class="mt-3">Ja</button>
  </div>
</div>

<div *ngIf="deleteDialogConfirmationPopup" class="backdrop"></div>
<div class="deleteConfirmation-popup-container"  *ngIf="deleteDialogConfirmationPopup">
  <h2> Sind Sie sicher, dass Sie den Benutzer “{{userForDelete.firstName}} {{userForDelete.lastName}}” löschen wollen?
  </h2>
  <div class="d-flex gap-4">
    <button (click)="onClickDeleteNein()" mat-stroked-button class="mt-3">Abbrechen</button>
    <button (click)="onClickDeleteJa()" mat-stroked-button class="mt-3">Ja</button>
  </div>
</div>

@if (isSpinnerActive) {
  <app-spinner></app-spinner>
}

<customer-management (updateUsersPlease)="getAllKeyCloakUser()"></customer-management>

<partner-management (updateUsersPlease)="getAllKeyCloakUser()"></partner-management>
