import { ChangeDetectorRef, Component, EventEmitter, Injectable, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { ProjectDataCacheService } from '../project-data-cache.service'
import { DialogService } from '../dialogservice.service'
import { AgentsService } from '../utils/services/agents.service';
import { KeycloakService } from 'keycloak-angular';
import { DateRange, MAT_DATE_RANGE_SELECTION_STRATEGY, MatDateRangeSelectionStrategy } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { SharedService } from '../utils/services/shared.service';
import { BookableTimeSlotsService } from '../utils/services/bookable-time-slots.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { TimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { DateFormatDirective } from '../utils/date-format.directive';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

const DateFormats = {
  parse: {
    dateInput: ['MM.DD.YYYY'],
  },
  display: {
    dateInput: 'MM.DD.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Injectable()
export class FiveDayRangeSelectionStrategy implements MatDateRangeSelectionStrategy<string> {
  constructor(private _dateAdapter: DateAdapter<string>) {
    this._dateAdapter.getFirstDayOfWeek = () => { return 1; };
  }
  selectionFinished(date: string | null): DateRange<string> {
    return this._createFiveDayRange(date);
  }

  createPreview(activeDate: string | null): DateRange<string> {
    return this._createFiveDayRange(activeDate);
  }

  private _createFiveDayRange(date: string | null): DateRange<any> {
    if (date) {
      const currentDate = new Date();
      const d = new Date(date);
      if(d < currentDate) {
        d.setDate(currentDate.getDate());
      }
      const day = d.getDay();
      const diff = d.getDate() - day + (day == 0 ? -6 : 1);
      const start = new Date(d.setDate(diff));
      const end = new Date(d.setDate(diff + 6));
      return new DateRange<any>(start, end);
    }

    return new DateRange<string>(null, null);
  }
}

@Component({
  selector: 'timeSlotsDialog',
  standalone: true,
  imports: [CommonModule, FormsModule, MaterialModule, TimePickerModule, DateFormatDirective],
  templateUrl: './timeSlotsDialog.component.html',
  styleUrls: ['./timeSlotsDialog.component.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: FiveDayRangeSelectionStrategy,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DateFormats },
  ],
})
export class TimeSlotsDialogComponent implements OnInit {
  @Input()
  public prefix: string = '';

  @Input()
  public agentId: number = 0;


  @Input()
  public projectId: any;

  @Output() scheduledDurationEvent = new EventEmitter<number>();

  @Input() min: any;

  @Input() currentUserRole: string = '';

  yesterday = new Date();

  public currentUser = '';
  startDate: any;
  endDate: any
  order: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  orders: any = [
    {
      german: "Montag",
      english: "Monday",
    },
    {
      german: "Dienstag",
      english: "Tuesday",
    },
    {
      german: "Mittwoch",
      english: "Wednesday",
    },
    {
      german: "Donnerstag",
      english: "Thursday",
    },
    {
      german: "Freitag",
      english: "Friday",
    },
    {
      german: "Samstag",
      english: "Saturday",
    },
    {
      german: "Sonntag",
      english: "Sunday",
    },
  ];
  timeSlots: { [key: string]: { from: string, to: string, status:string }[] } = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: []
  };
  currentTimeSlots: { [key: string]: { from: string, to: string, status:string }[] } = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: []
  };
  timeSlotError: boolean = true;
  slotOverlapError: boolean = false;
  preserveTimeSlots: any;
  totalDurationInMinutesForWeek: number = 0;
  currentDate = new Date();
  sheduledStatus: any;
  areAllArraysEmpty = true;
  selectedTimeSlot = {};
  public bookedTimeSlots: any;

  public interval: number = 15;
  public formatString: string = 'HH:mm';
  errorZigZag: boolean[] = [];

  public constructor(
    public readonly projectDataCacheService: ProjectDataCacheService,
    public dialogService: DialogService,
    private agentsService: AgentsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly keycloak: KeycloakService,
    private dateAdapter: DateAdapter<any>,
    private sharedService: SharedService,
    private viewContainerRef: ViewContainerRef,
    private btsService: BookableTimeSlotsService,
    public snackBar: MatSnackBar
    ) {
      this.yesterday.setDate(this.yesterday.getDate() - 0);
  }

  public ngOnInit(): void {
    // this.dateAdapter.setLocale('gu-IN');
    this.dateAdapter.setLocale('de-DE');
    const tokenData = this.keycloak.getKeycloakInstance().tokenParsed;
    this.currentUser = tokenData?.['email'];

    this.totalDurationInMinutesForWeek = this.calculateTotalDurationInMinutesForWeek(this.order, this.timeSlots);
    // this.scheduledDurationEvent.emit(this.totalDurationInMinutesForWeek);

    this.btsService.getAllBookableTimeSlotsByProjectId(this.projectId).subscribe((timeSlots: any) => {
      this.bookedTimeSlots = timeSlots;

      if (this.bookedTimeSlots && this.bookedTimeSlots.length > 0) {
        const timeSlotsLength = this.bookedTimeSlots.length;
        this.selectedTimeSlot = this.bookedTimeSlots[timeSlotsLength - 1];
        this.timeSlots = this.bookedTimeSlots[timeSlotsLength - 1].timeWindows;
        this.currentTimeSlots = this.bookedTimeSlots[timeSlotsLength - 1].timeWindows;
        this.startDate = this.bookedTimeSlots[timeSlotsLength - 1].fromDate;
        this.endDate = this.bookedTimeSlots[timeSlotsLength - 1].toDate;
        this.areAllArraysEmpty = Object.keys(this.timeSlots).every(key => this.timeSlots[key].length === 0);
        this.timeSlots = this.mergeAdjacentTimeSlotsInHalfHour(this.timeSlots);
      }
    });

    this.sharedService.scheduledStatusSubject.subscribe((scheduledStatus) => {
      this.sheduledStatus = scheduledStatus;
    })

    this.areAllArraysEmpty = Object.keys(this.timeSlots).every(key => this.timeSlots[key].length === 0);

  }

  validateForm(): boolean {
    for (const day in this.timeSlots) {
      if (this.timeSlots[day].length === 0) {
        return true;
      }
      for (const slot of this.timeSlots[day]) {
        if (slot.from === '' || slot.to === '') {
          return true;
        }
      }
    }
    return false;
  }

  onDateChange(event: any) {
    const toLocalISOString = (date: Date) => {
      const tzOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
      const localISOTime = new Date(date.getTime() - tzOffset).toISOString();
      return localISOTime.split('T')[0]; // return date part only
    };

    const startDateString = toLocalISOString(this.startDate);
    const endDateString = toLocalISOString(this.endDate);

    const foundTimeSlot = this.bookedTimeSlots.find((slot: any) => {
      const slotStartDateString = toLocalISOString(new Date(slot.fromDate));
      const slotEndDateString = toLocalISOString(new Date(slot.toDate));
      return slotStartDateString === startDateString && slotEndDateString === endDateString;
    });

    if (foundTimeSlot) {
      this.timeSlots = foundTimeSlot.timeWindows;
      this.currentTimeSlots = foundTimeSlot.timeWindows;
      this.selectedTimeSlot = foundTimeSlot;
    } else {
      this.timeSlots = {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: []
      };
      this.currentTimeSlots = {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: []
      };
      this.selectedTimeSlot = {};
    }
    this.areAllArraysEmpty = Object.keys(this.timeSlots).every(key => this.timeSlots[key].length === 0);
    this.timeSlots = this.mergeAdjacentTimeSlotsInHalfHour(this.timeSlots);
  }

  validateTimeSlot(fromTime: string, toTime: string): boolean {
    let error = true;
    this.timeSlotError = true;
    const fromMinutes = this.getMinutesFromTime(fromTime);
    const toMinutes = this.getMinutesFromTime(toTime);
    if ((toMinutes - fromMinutes) >= 30) {
        error = false;
        this.timeSlotError = false;
    }
    return error;
}

  isMultipleOf30Minutes(fromTime: string, toTime: string): boolean {
    let error = true;
    this.timeSlotError = true;
    const fromMinutes = this.getMinutesFromTime(fromTime);
    const toMinutes = this.getMinutesFromTime(toTime);
    const intervalMinutes = toMinutes - fromMinutes;
    if (intervalMinutes % 30 === 0) {
      error = false;
      this.timeSlotError = false;
    }
    return error;
  }

  getMinutesFromTime(time: string): number {
    try{
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    }catch(err){
      const times = new Date(time);
      const hours = times.getHours();
      const minutes = times.getMinutes();
      return hours * 60 + minutes;
    }
  }

  isForValidUpdate() {

  }

  saveAndCloseDialog(): void {
    for (const [day, slots] of Object.entries(this.timeSlots)) {
      for (const slot of slots) {
        if (slot.from && typeof slot.from === 'object') {
          const time = new Date(slot.from);
          const hours = time.getHours();
          const minutes = time.getMinutes();
          slot.from = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        }
        if (slot.to && typeof slot.to === 'object') {
          const time = new Date(Date.parse(slot.to));
          const hours = time.getHours();
          const minutes = time.getMinutes();
          slot.to = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        }
      }
    }
    // Here we send the time slots to the backend
    this.totalDurationInMinutesForWeek = this.calculateTotalDurationInMinutesForWeek(this.order, this.timeSlots);
    this.timeSlots = this.divideTimeSlotInHalfHour(this.timeSlots);
    const bookableTimeSlot: any = {
      slotDuration: this.totalDurationInMinutesForWeek,
      client: this.currentUser,
      fromDate: this.startDate,
      toDate: this.endDate,
      timeWindows: this.timeSlots,
      projectId: this.projectId
    }
    // this.timeSlots = bookableTimeSlot;
    this.areAllArraysEmpty = Object.keys(this.timeSlots).every(key => this.timeSlots[key].length === 0);

    this.scheduledDurationEvent.emit(bookableTimeSlot);

    this.agentsService.bookTimeSlotsForAgent(bookableTimeSlot).subscribe((res) => {
      // this.totalDurationInMinutesForWeek = this.calculateTotalDurationInMinutesForWeek(this.order, this.timeSlots);
      // if(res) {
        // this.scheduledDurationEvent.emit(this.totalDurationInMinutesForWeek);
      // }
    })
    this.dialogService.showTimeSlotDialog = false
  }

  isAllUpdateTimestampsAllow(originalData: any, updatedTimeWindows: any): { changed: boolean, details?: { day: string, slot: any } } {
    const extractLocalTime = (utcDate: string): string => {
      const date = new Date(utcDate);
      const localHours = date.getHours().toString().padStart(2, '0');
      const localMinutes = date.getMinutes().toString().padStart(2, '0');
      return `${localHours}:${localMinutes}`;
    };

    for (const day in updatedTimeWindows) {
      updatedTimeWindows[day] = updatedTimeWindows[day].map((slot: any) => {

        if (typeof slot.from === 'object' && slot.from instanceof Date) {
          slot.from = extractLocalTime(slot.from.toISOString());
        }
        if (typeof slot.to === 'object' && slot.to instanceof Date) {
          slot.to = extractLocalTime(slot.to.toISOString());
        }

        return {
          ...slot,
          from: typeof slot.from === 'string' && slot.from.includes('T') ? extractLocalTime(slot.from) : slot.from,
          to: typeof slot.to === 'string' && slot.to.includes('T') ? extractLocalTime(slot.to) : slot.to
        };
      });
    }

    const splitInto30MinuteSlots = (slots: any[]): any[] => {
      const result: any = [];
      slots.forEach(slot => {
        let fromTime = new Date(`1970-01-01T${slot.from}:00Z`).getTime();
        const toTime = new Date(`1970-01-01T${slot.to}:00Z`).getTime();

        while (fromTime < toTime) {
          const nextSlot = new Date(fromTime + 30 * 60000).toISOString().substring(11, 16);
          result.push({ from: new Date(fromTime).toISOString().substring(11, 16), to: nextSlot, status: slot.status });
          fromTime += 30 * 60000;
        }
      });
      return result;
    };

    // // Create a map of all occupied original slots
    // const originalOccupiedSlots = new Map<string, any>();
    // originalData.forEach(item => {
    //   for (const day in item.timeWindows) {
    //     const slots = splitInto30MinuteSlots(item.timeWindows[day]);
    //     slots.forEach(slot => {
    //       if (slot.status === 'occupy') {
    //         const key = `${day}-${slot.from}-${slot.to}`;
    //         originalOccupiedSlots.set(key, slot);
    //       }
    //     });
    //   }
    // });

    // Create a map of all occupied original slots
const originalOccupiedSlots = new Map<string, any>();

for (const day in originalData) {
  const slots = originalData[day];
  slots.forEach((slot: any) => {
    if (slot.status === 'occupy') {
      const fromTime = slot.from instanceof Date ? this.extractLocalTime(slot.from) : slot.from;
      const toTime = slot.to instanceof Date ? this.extractLocalTime(slot.to) : slot.to;
      const key = `${day}-${fromTime}-${toTime}`;
      originalOccupiedSlots.set(key, slot);
    }
  });
}


    // Compare with updated time windows
    for (const day in updatedTimeWindows) {
      const slots = splitInto30MinuteSlots(updatedTimeWindows[day]);
      const matchedSlots = new Set<string>();

      for (const slot of slots) {
        if (slot.status === 'occupy') {
          const key = `${day}-${slot.from}-${slot.to}`;
          if (originalOccupiedSlots.has(key)) {
            matchedSlots.add(key); // Mark the slot as matched
          }
        }
      }

      matchedSlots.forEach(key => {
        originalOccupiedSlots.delete(key);
      });
    }

    // Check if there are any remaining occupied slots that were not matched
    if (originalOccupiedSlots.size > 0) {
      const [key, slot] = originalOccupiedSlots.entries().next().value;
      const [day] = key.split('-');
      return { changed: true, details: { day, slot } };
    }

    return { changed: false };
  }

  isExistingSlot(slot: any, day: string) {
    const startDate = new Date(this.startDate);
    const endDate = new Date(this.endDate);

    const startDateString = startDate.toLocaleDateString('en-GB');
    const endDateString = endDate.toLocaleDateString('en-GB');

    const foundTimeSlot = this.bookedTimeSlots.find((slot: any) => {
      const slotStartDate = new Date(slot.fromDate);
      const slotEndDate = new Date(slot.toDate);

      // Convert slot dates to local date strings
      const slotStartDateString = slotStartDate.toLocaleDateString('en-GB');
      const slotEndDateString = slotEndDate.toLocaleDateString('en-GB');

      return slotStartDateString === startDateString && slotEndDateString === endDateString;
    });

    if (foundTimeSlot) {
      const daySlots = foundTimeSlot.timeWindows[day] || [];
      const existing = daySlots.find((s: any) => s.from === slot.from && s.to === slot.to);
      return existing;
    }
    return null;
  }


  updateAndCloseDialog() {
    const changed = this.isAllUpdateTimestampsAllow(this.currentTimeSlots, this.timeSlots);
    const germanDay = this.orders.find((order: { english: string | undefined; }) => order.english === changed.details?.day)?.german || changed.details?.day;
    if (changed.changed == false) {
      for (const [day, slots] of Object.entries(this.timeSlots)) {
        for (const slot of slots) {
          if (slot.from && typeof slot.from === 'object') {
            const time = new Date(slot.from);
            const hours = time.getHours();
            const minutes = time.getMinutes();
            slot.from = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
          }
          if (slot.to && typeof slot.to === 'object') {
            const time = new Date(Date.parse(slot.to));
            const hours = time.getHours();
            const minutes = time.getMinutes();
            slot.to = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
          }
        }
      }
      this.totalDurationInMinutesForWeek = this.calculateTotalDurationInMinutesForWeek(this.order, this.timeSlots);
      this.timeSlots = this.divideTimeSlotInHalfHour(this.timeSlots);

      for (const [day, slots] of Object.entries(this.timeSlots)) {
        for (const slot of slots) {
          if (slot.from && typeof slot.from === 'object') {
            const time = new Date(slot.from);
            const hours = time.getHours();
            const minutes = time.getMinutes();
            slot.from = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
          }
          if (slot.to && typeof slot.to === 'object') {
            const time = new Date(Date.parse(slot.to));
            const hours = time.getHours();
            const minutes = time.getMinutes();
            slot.to = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
          }
          const isExisting = this.isExistingSlot(slot, day);
          if(!isExisting) {
            slot.status = 'vacant';
          }
        }
      }

      // this.timeSlots.map((slot) => {})
      const bookableTimeSlot: any = {
        slotDuration: this.totalDurationInMinutesForWeek,
        client: this.currentUser,
        fromDate: this.startDate,
        toDate: this.endDate,
        timeWindows: this.timeSlots
      }
      this.timeSlots = bookableTimeSlot;
      this.currentTimeSlots = bookableTimeSlot;
      this.scheduledDurationEvent.emit(bookableTimeSlot);

      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);

      // Convert to local date strings
      const startDateString = startDate.toLocaleDateString('en-GB');
      const endDateString = endDate.toLocaleDateString('en-GB');

      const foundTimeSlot = this.bookedTimeSlots.find((slot: any) => {
        const slotStartDate = new Date(slot.fromDate);
        const slotEndDate = new Date(slot.toDate);

        // Convert slot dates to local date strings
        const slotStartDateString = slotStartDate.toLocaleDateString('en-GB');
        const slotEndDateString = slotEndDate.toLocaleDateString('en-GB');

        return slotStartDateString === startDateString && slotEndDateString === endDateString;
      });

      this.agentsService.updateBookedTimeSlotsForAgent(foundTimeSlot?.id, bookableTimeSlot).subscribe((res: any) => { })
      this.dialogService.showTimeSlotDialog = false;
    } else {
      this.snackBar.open(`Sie können nicht aktualisieren, da der Slot ${germanDay} ${changed.details?.slot.from}-${changed.details?.slot.to} besetzt ist` , '', {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'top',
      });
      if (this.bookedTimeSlots && this.bookedTimeSlots.length > 0) {
        const fromDateToFind = new Date(this.startDate);
        const toDateToFind = new Date(this.endDate);

        const index = this.bookedTimeSlots.findIndex((item: { fromDate: string | number | Date; toDate: string | number | Date; }) => {
          const itemFromDate = new Date(item.fromDate);
          const itemToDate = new Date(item.toDate);
          return itemFromDate.toDateString() === fromDateToFind.toDateString() && itemToDate.toDateString() === toDateToFind.toDateString();
        });

        this.timeSlots = this.bookedTimeSlots[index].timeWindows;
        this.currentTimeSlots = this.bookedTimeSlots[index].timeWindows;
        this.timeSlots = this.mergeAdjacentTimeSlotsInHalfHour(this.timeSlots);
      }
    }
  }

  extractLocalTime(utcDate: string): string {
    const date = new Date(utcDate);
    const localHours = date.getHours().toString().padStart(2, '0');
    const localMinutes = date.getMinutes().toString().padStart(2, '0');
    return `${localHours}:${localMinutes}`;
  }

  closeTimeSlotModal() {
    this.bookedTimeSlots = [];
    this.timeSlots = {};
    this.currentTimeSlots = {};
    this.dialogService.showTimeSlotDialog = false;
  }

  addSlotFor(day: string) {
    if (this.isDayWithinRange(day)) {
      if (!this.timeSlots[day]) {
        this.timeSlots[day] = [];
      }
      this.timeSlots[day].push({ from: '', to: '', status: 'vacant' });
      this.changeDetectorRef.detectChanges();
    }
  }

  removeSlotForDay(day: string, index: number) {
    this.timeSlots[day].splice(index, 1);
  }

  calculateTotalDurationInMinutesForDay(day: string, timeSlots: { [key: string]: { from: string; to: string }[] }): number {
  let totalDurationInMinutes = 0;

  if (timeSlots[day]) {
      timeSlots[day].forEach(slot => {
          const [fromHours, fromMinutes] = slot.from.split(':').map(Number);
          const [toHours, toMinutes] = slot.to.split(':').map(Number);

          totalDurationInMinutes += (toHours * 60 + toMinutes) - (fromHours * 60 + fromMinutes);
      });
  }

  return totalDurationInMinutes;
 }

  calculateTotalDurationInMinutesForWeek(order: string[], timeSlots: { [key: string]: { from: string; to: string }[] }): number {
    let totalDurationInMinutes = 0;

    order.forEach(day => {
        totalDurationInMinutes += this.calculateTotalDurationInMinutesForDay(day, timeSlots);
    });

    return totalDurationInMinutes;
  }

  getSchedleDurtion(timeSlots: any, day: string, index: number) {
    if (timeSlots[day].length > 0) {
      new Promise<void>((resolve) => resolve())
      .then(() => {
        const allSlotsValid = timeSlots[day].every((slot: any) => {
          return slot.hasOwnProperty('from') && slot.hasOwnProperty('to') &&
              slot.from !== '' && slot.to !== '';
        });
        if (allSlotsValid) {
          this.areAllArraysEmpty = false;
        }
      });
    } else {
      this.areAllArraysEmpty = true;
    }

    for(let i=0; i<timeSlots[day].length; i++){
      this.errorZigZag[i] = false;
    }
    this.errorZigZag[index] = true;
    this.slotOverlapError = false;
    // this.changeDetectorRef.detectChanges();
  }

  divideTimeSlot(slot: { from: string; to: string; status: string }) {
    const newSlots = [];
    const startTime = new Date(`01/01/1970 ${slot.from}`);
    const endTime = new Date(`01/01/1970 ${slot.to}`);
    let currentTime = new Date(startTime);

    while (currentTime < endTime) {
      const newSlot = {
        from: currentTime.toLocaleTimeString('en-US', {
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
        }),
        to: new Date(currentTime.getTime() + 30 * 60000).toLocaleTimeString(
          'en-US',
          { hour12: false, hour: '2-digit', minute: '2-digit' }
        ),
        status: slot.status,
      };
      newSlots.push(newSlot);
      currentTime = new Date(currentTime.getTime() + 30 * 60000);
    }
    return newSlots;
  }

  divideTimeSlotInHalfHour(inputObj: {
    [key: string]: { from: string; to: string; status: string }[];
  }) {
    const outputObj: {
      [key: string]: { from: string; to: string; status: string }[];
    } = {};

    for (const [day, slots] of Object.entries(inputObj)) {
      const newSlots = [];
      for (const slot of slots) {
        const slotFrom = new Date(`01/01/1970 ${slot.from}`).getTime();
        const slotTo = new Date(`01/01/1970 ${slot.to}`).getTime();
        if ((slotTo - slotFrom) / 1000 > 1800) {
          newSlots.push(...this.divideTimeSlot(slot));
        } else {
          newSlots.push(slot);
        }
      }
      outputObj[day] = newSlots;
    }

    return outputObj;
  }

  mergeAdjacentTimeSlots(
    slots: { from: string; to: string; status: string }[]
  ) {
    if (slots.length === 0) {
      return [];
    }
    const mergedSlots = [];

    let currentSlot = { ...slots[0] };
    for (let i = 1; i < slots.length; i++) {
      const nextSlot = slots[i];

      if (
        currentSlot.status === nextSlot.status &&
        currentSlot.to === nextSlot.from
      ) {
        currentSlot.to = nextSlot.to;
      } else {
        mergedSlots.push(currentSlot);
        currentSlot = { ...nextSlot };
      }
    }

    mergedSlots.push(currentSlot);

    return mergedSlots;
  }

  mergeAdjacentTimeSlotsInHalfHour(inputObj: {
    [key: string]: { from: string; to: string; status: string }[];
  }) {
    const outputObj: {
      [key: string]: { from: string; to: string; status: string }[];
    } = {};

    for (const [day, slots] of Object.entries(inputObj)) {
      const mergedSlots = this.mergeAdjacentTimeSlots(slots);
      outputObj[day] = mergedSlots;
    }

    return outputObj;
  }

  isObjectEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }

  isDayWithinRange(day: string): boolean {
    if(!this.startDate && !this.endDate){
      return false;
    }
    const currentDate = new Date();
    const currentDay = (currentDate.getDay() + 6) % 7;

    const startDate = new Date(this.startDate);
    const endDate = new Date(this.endDate);

    if (currentDate < startDate) {
      return true;
    } else if (currentDate > endDate) {
      return false;
    }

    const index = this.order.findIndex((days) => days === day);
    const isDayPast = currentDay >= index;
    return !isDayPast;
  }

  getFormattedFrom(from: any) {
    if (typeof from !== 'string') {
        if (from instanceof Date && !isNaN(from.getTime())) {
            // 'from' is a valid Date object and selected as new
            const date = new Date(from.getTime() + 15 * 60000); // Add 15 minutes
            const year = date.getFullYear().toString().padStart(4, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}`;
        } else {
            // 'from' is not a valid Date object, return it as is
            return from;
        }
    } else {
        // 'from' is a string (Got saved from DB)
        const [hours = "", minutes = ""] = from.split(':');
        if (!hours || !minutes) {
            return "";
        }
        const parsedHours = parseInt(hours, 10);
        const parsedMinutes = parseInt(minutes, 10);
        if (parsedHours < 0 || parsedHours > 23 || parsedMinutes < 0 || parsedMinutes > 59) {
            return "";
        }
        const totalMinutes = parsedHours * 60 + parsedMinutes + 15;
        const newHours = Math.floor(totalMinutes / 60);
        const newMinutes = totalMinutes % 60;
        const date = new Date();
        date.setHours(newHours, newMinutes);
        const year = date.getFullYear().toString().padStart(4, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
    }
  }

  zigZagValidation(day: string, currentSlotIndex: number): boolean {
    const slots = this.timeSlots[day];
    if (!slots) return true;

    const currentSlot = slots[currentSlotIndex];
    for (let i = 0; i < slots.length; i++) {
        if (i === currentSlotIndex) continue;
        const slot = slots[i];

        const currentFrom = this.parseTime(currentSlot.from);
        const currentTo = this.parseTime(currentSlot.to);
        const slotFrom = this.parseTime(slot.from);
        const slotTo = this.parseTime(slot.to);

        if (
            (currentFrom < slotTo && currentTo > slotFrom) ||
            (currentFrom <= slotFrom && currentTo >= slotTo) ||
            (currentFrom > slotFrom && currentFrom < slotTo && currentTo < slotTo) ||
            (currentFrom < slotFrom && currentTo > slotFrom && currentTo > slotTo)
        ) {
            this.slotOverlapError = true;
            // this.changeDetectorRef.detectChanges();
            return false;
        }
    }
    // this.slotOverlapError = false;
    return true;
  }

  parseTime(time: any): Date {
    if (time instanceof Date) {
      const date = new Date();
      const hours = time.getHours();
      const minutes = time.getMinutes();
      date.setHours(hours, minutes, 0, 0);
      return date;
    }
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  }
}
