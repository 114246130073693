import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PartnersService } from '../utils/services/partners.service';
import { Partner } from '../utils/models/partner.model';
import { DialogService } from '../dialogservice.service';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../spinner/spinner.component';
import { NewProjectComponent } from '../newProject/newProject.component';
import { NewPartnerAssignmentComponent } from './new-partner-assignment/new-partner-assignment.component';
import { MatButtonModule } from '@angular/material/button';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'partner-management',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    SwitchModule,
    NewProjectComponent,
    NewPartnerAssignmentComponent,
    SpinnerComponent,
    MatTooltipModule
  ],
  templateUrl: './partner-management.component.html',
  styleUrl: './partner-management.component.scss'
})
export class PartnerManagementComponent implements OnInit {
  public isSpinnerActive: boolean = false;

  public deleteConfirmationPopup: boolean = false;
  public partnerForDelete?: { partner: Partner, user: any } = undefined;
  public selectedPartner?: Partner = undefined;

  public deactivateConfirmationPopup: boolean = false;
  public partnerForDeactivate?: Partner = undefined;
  public currentSwitch: any = {};

  @Output() public updateUsersPlease: EventEmitter<void> = new EventEmitter<void>();

  public constructor(
    public readonly partnersService: PartnersService,
    public readonly dialogService: DialogService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  public ngOnInit(): void {
    this.isSpinnerActive = true;

    this.partnersService.fetchAll(() => {
      this.isSpinnerActive = false;
    });
  }

  public addPartnerAssignment(): void {
    this.dialogService.showNewPartnerDialog = true;
    this.partnersService.isForUpdate = false;
    this.selectedPartner = undefined;
  }

  public onSwitch(event: any, partner: Partner, sw: any): void {
    this.partnerForDeactivate = partner;
    this.currentSwitch = sw;

    if (event.checked) {
      this.partnersService.reactivate(partner).subscribe(() => {
        this.deactivateConfirmationPopup = false;
        this.partnerForDeactivate = undefined;
        this.currentSwitch = {};
        this.partnersService.fetchAll(() => {});
        this.updateUsersPlease.emit();
      });
    } else {
      this.deactivateConfirmationPopup = true;
    }
  }

  public onSwitchDialogYes(): void {
    this.partnersService.deactivate(this.partnerForDeactivate!).subscribe(() => {
      this.deactivateConfirmationPopup = false;
      this.partnerForDeactivate = undefined;
      this.currentSwitch = {};
      this.partnersService.fetchAll(() => {});
      this.updateUsersPlease.emit();
    });
  }

  public onSwitchDialogNo(): void {
    setTimeout(() => {
      if (this.partnerForDeactivate?.isActivated) {
        this.partnerForDeactivate!.isActivated = true;
      }
      this.currentSwitch.checked = true;
      this.changeDetectorRef.detectChanges();

      this.deactivateConfirmationPopup = false;
      this.partnerForDeactivate = undefined;
      this.currentSwitch = {};
    }, 500);
  }

  public onClickDelete(partnerAssignment: { partner: Partner, user: any }): void {
    this.partnerForDelete = partnerAssignment;
    this.deleteConfirmationPopup = true;
  }

  public onClickUser(partnerAssignment: { partner: Partner, user: any }): void {
    this.selectedPartner = partnerAssignment.partner;
    this.partnersService.isForUpdate = true;
    this.dialogService.showNewPartnerDialog = true;
  }

  public onClickJa(): void {
    this.partnersService.delete(this.partnerForDelete!.partner.id).subscribe(() => {
      this.partnersService.partnerAssignments = this.partnersService.partnerAssignments.filter(
        (assignment) => assignment.partner.id !== this.partnerForDelete!.partner.id
      );
      this.deleteConfirmationPopup = false;
      this.partnerForDelete = undefined;
    });
  }
}
