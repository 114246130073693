import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[date-format]',
  standalone: true
})
export class DateFormatDirective {
  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
    this.el = elementRef.nativeElement;
  }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    const value = this.el.value.replace(/[^0-9]/g, ''); 
    let formattedValue = '';
    if (value.length >= 1 && value.length <= 2) {
      formattedValue = value;
    } else if (value.length >= 3 && value.length <= 4) {
      const month = value.substring(0, 2);
      const day = value.substring(2, 4);
      formattedValue = `${month}.${day}`;
    } else if (value.length > 4) {
      const month = value.substring(0, 2);
      const day = value.substring(2, 4);
      const year = value.substring(4, 8);
      formattedValue = `${month}.${day}.${year}`;
    }

    this.el.value = formattedValue; 
  }

  @HostListener('blur')
  onBlur() {
    this.validateAndFormat();
  }

  @HostListener('focusout')
  onFocusOut() {
    this.validateAndFormat();
  }

  private validateAndFormat() {
    const value = this.el.value.trim();
    const parts = value.split('.');

    if (parts.length === 3) {
      const month = parts[0];
      const day = parts[1];
      const year = parts[2];

      if (this.isValidMonth(month) && this.isValidDay(day) && this.isValidYear(year)) {
        const formattedMonth = month.padStart(2, '0');
        const formattedDay = day.padStart(2, '0');
        const formattedYear = year.length === 2 ? `20${year}` : year;

        this.el.value = `${formattedMonth}.${formattedDay}.${formattedYear}`;
      } else {
        this.el.value = '';
      }
    } else {
      this.el.value = '';
    }
  }

  private isValidDay(day: string): boolean {
    const dayNumber = Number(day);
    return dayNumber >= 1 && dayNumber <= 31;
  }

  private isValidMonth(month: string): boolean {
    const monthNumber = Number(month);
    return monthNumber >= 1 && monthNumber <= 12;
  }

  private isValidYear(year: string): boolean {
    const yearNumber = Number(year);
    return year.length === 2 || year.length === 4;
  }
}